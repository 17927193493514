<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['approval_status','plan_status','place_status','place_is_book','order_status','approval_type_id','totalPrice']" ref="list" :rowKey="'order_id'"
			:submit_preprocessing="submit_preprocessing"
			:columns_setting_show="true"
			:cate_list="cate_list"
			:scroll="{x:true}"
			:is_created_get_list="false"
			@screen_columns_ok="screen_columns_ok"
			emptyText="没有筛选出您需要的内容,请修改筛选条件后重新搜索">
			<template slot="right_btn">
				<!-- <a :href="'/manage/crm.trip_export/tripExport' | export_form"  target="_blank"> -->
				<a-button class="a_btn" @click="report()">导出</a-button>
				<!-- </a> -->
			</template>
			<template slot="approval_status" slot-scope="data">
				<span v-if="data.record.approval_status === -2">已撤回</span>
				<span v-if="data.record.approval_status === -1">已拒绝</span>
				<span v-if="data.record.approval_status === 0">待审批</span>
				<span v-if="data.record.approval_status === 1">审批中</span>
				<span v-if="data.record.approval_status === 2">已审批</span>
			</template>
			<template slot="plan_status" slot-scope="data">
				<span v-if="data.record.plan_status === 0">正常</span>
				<span v-if="data.record.plan_status === -1">已取消</span>
			</template>
			<template slot="place_status" slot-scope="data">
				<span v-if="data.record.place_status === 0">正常</span>
				<span v-if="data.record.place_status === -1">已取消</span>
			</template>
			<template slot="place_is_book" slot-scope="data">
				<span v-if="data.record.place_is_book === 0">否</span>
				<span v-if="data.record.place_is_book === 1">是</span>
			</template>
			<template slot="approval_type_id" slot-scope="data">
				<span v-if="data.record.approval_type_id == 'business_trip'">出差申请</span>
				<span v-if="data.record.approval_type_id == 'jurisdiction'">辖区内</span>
				<span v-if="data.record.approval_type_id == 'hotel'">酒店代订</span>
				<span v-if="data.record.approval_type_id == 'countryside'">下乡</span>
			</template>
			<template slot="totalPrice" slot-scope="data">
				<span>{{data.record.totalPrice + '元'}}</span>
			</template>
			
		</TableList>
	
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import SelectUser from "@/components/SelectUser";

	import {
		tripList,
		getApprovalTypeSelect,
		tripExport
	} from "@/api/evection";
	import {
		getUserList
	} from "@/api/personnel";

	const columns = [
		{
			title: "人员ID",
			dataIndex: "traveler",
			cate_name:"审批字段",
			width:150,
		},
		{
			title: "人员姓名",
			dataIndex: "traveler_name",
			cate_name:"审批字段",
			width:150,
		},
		{
			title: "部门",
			dataIndex: "traveler_department_name",
			cate_name:"审批字段",
			width:260,
		},
		{
			title: "岗位",
			dataIndex: "traveler_role_name",
			cate_name:"审批字段",
			width:150,
		},
		{
			title: "审批类型",
			dataIndex: "approval_type_id",
			cate_name:"审批字段",
			width:150,
			scopedSlots: {
				customRender:'approval_type_id'
			},
		},
		{
			title: "审批状态",    //审批状态-2已撤回 -1已拒绝 0待审批 1审批中 2已审批
			dataIndex:"approval_status",
			cate_name:"审批字段",
			width:100,
			scopedSlots: {
				customRender:'approval_status'
			},
		},
		{
			title: "出差事由",
			dataIndex:"reason",
			cate_name:"审批字段",
			width:260,
		},
		{
			title: "计划开始时间",
			dataIndex:"plan_start_time",
			cate_name:"审批字段",
			width:160,
		},
		{
			title: "计划结束时间",
			dataIndex:"plan_end_time",
			cate_name:"审批字段",
			width:160,
		},
		{
			title: "计划出发地",
			dataIndex:"plan_start_region",
			cate_name:"审批字段",
			width:260,
		},
		{
			title: "计划到达地",
			dataIndex:"plan_end_region",
			cate_name:"审批字段",
			width:260,
		},
		{
			title: "计划状态",    //计划状态 0正常 -1已取消
			dataIndex:"plan_status",
			cate_name:"审批字段",
			width:100,
			scopedSlots: {
				customRender:'plan_status'
			},
		},
		// 行程字段
		{
			title: "行程状态",    //计划状态 0正常 -1已取消
			dataIndex:"place_status",
			cate_name:"行程字段",
			width:100,
			scopedSlots: {
				customRender:'place_status'
			},
		},
		{
			title:"行程出发地",
			dataIndex:"start_place",
			cate_name:"行程字段",
			width:260,
		},
		{
			title:"出发打卡时间",
			dataIndex:"place_start_clock_time",
			cate_name:"行程字段",
			width:160,
		},
		{
			title:"出发打卡地点",
			dataIndex:"place_start_clock_region",
			cate_name:"行程字段",
			width:260,
		},
		{
			title:"行程到达地",
			dataIndex:"end_place",
			cate_name:"行程字段",
			width:260,
		},
		{
			title:"到达打卡时间",
			dataIndex:"place_end_clock_time",
			cate_name:"行程字段",
			width:160,
		},
		{
			title:"到达打卡地点",
			dataIndex:"place_end_clock_region",
			cate_name:"行程字段",
			width:260,
		},
		{
			title:"是否入住酒店",
			dataIndex:"place_is_book", //是否预定酒店 0否1是
			cate_name:"行程字段",
			width:100,
			scopedSlots: {
				customRender:'place_is_book'
			},
		},
		{
			title:"酒店订单状态",
			dataIndex:"order_status", // 1:已入住 -1:已退款
			cate_name:"酒店字段",
			width:160,
		},
		{
			title:"酒店名称",
			dataIndex:"hotelName",
			cate_name:"酒店字段",
			width:260,
		},
		{
			title:"酒店地点",
			dataIndex:"hotel_address",
			cate_name:"酒店字段",
			width:260,
		},
		{
			title:"入住时间",
			dataIndex:"checkinDate",
			cate_name:"酒店字段",
			width:160,
		},
		/* 此处为酒店打卡，由于后端暂时无法提供因此暂时隐藏 */
		{
			title:"入住打卡时间",
			dataIndex:"order_start_clock_time",
			cate_name:"酒店字段",
			width:160,
		},
		{
			title:"入住打卡地点",
			dataIndex:"order_start_clock_region",
			cate_name:"酒店字段",
			width:260,
		},
		{
			title:"离店时间",
			dataIndex:"checkoutDate",
			cate_name:"酒店字段",
			width:160,
		},
		{
			title:"离店打卡时间",
			dataIndex:"order_end_clock_time",
			cate_name:"酒店字段",
			width:160,
		},
		{
			title:"离店打卡地点",
			dataIndex:"order_end_clock_region",
			cate_name:"酒店字段",
			width:260,
		},
		{
			title:"结算金额",
			dataIndex:"totalPrice",
			cate_name:"酒店字段",
			width:260,
			scopedSlots: {
				customRender:'totalPrice'
			},
		},
		
	];

	// tableList中默认选中及列表中显示的列/字段
	const default_selected_columns = [
		'traveler_name',
		'traveler_department_name',
		'traveler_role_name',
		'approval_type_id',
		'approval_status',
		'plan_start_time',
		'plan_end_time',
		'start_place',
		'end_place',
		'place_is_book'
	];

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
			SelectUser
		},
		data() {
			return {
				cate_list:["审批字段","行程字段","酒店字段"],
				config: this.$config,
				get_table_list: tripList,
				columns,
				default_selected_columns,// tableList中默认选中及列表中显示的列/字段
				selectedRowKeys: [],
				loading: false,
				visible: false,
				submit_preprocessing: {
					array_to_string: ['department_id','role_id','approval_type','place_clock','order_clock']
				},
				form_data_seo: {
					list: [
						{
							type: "text",
							name: "user_search",
							title: "人员信息",
							placeholder: "人员ID/姓名",
							options: {}
						},
						{
						    type: "tree-select",
						    name: "department_id",
						    title: "部门",
						    options: {},
						    treeData: [],
						    multiple: true
						},
						{
						    type: "tree-select",
						    name: "role_id",
						    title: "岗位职务",
						    options: {},
						    treeData: [],
						    multiple: true
						},
						// {
						//     type: "select",
						//     name: "role_id",
						//     title: "岗位职务",
						//     mode: "multiple",
						//     options: {},
						//     list: []
						// },
						{
						    type: "select",
						    name: "approval_type",
						    title: "审批类型",
						    mode: "multiple",
						    options: {},
						    list: []
						},
						{
						    type: "select",
						    name: "plan_status",
						    title: "行程计划状态",
						    mode: "default",
						    options: {},
						    list: [
								{
									value:"正常",
									key:0
								},
								{
									value:"已取消",
									key:-1
								},
							]
						},
						{
						    type: "select",
						    name: "is_book",
						    title: "是否预定酒店",
						    mode: "default",
						    options: {},
						    list: [
								{
									value:"是",
									key:1
								},
								{
									value:"否",
									key:0
								},
							]
						},
						{
						    type: "select",
						    name: "place_clock",
						    title: "行程打卡",
						    mode: "multiple",
						    options: {},
						    list: [
								{
									value:"出发已打卡",
									key:'start_on_clock'
								},
								{
									value:"出发未打卡",
									key:'start_not_clock'
								},
								{
									value:"到达已打卡",
									key:'end_on_clock'
								},
								{
									value:"到达未打卡",
									key:'end_not_clock'
								},
							]
						},
						{
						    type: "select",
						    name: "place_status",
						    title: "行程状态",
						    mode: "default",
						    options: {},
						    list: [
								{
									value:"正常",
									key:0
								},
								{
									value:"已取消",
									key:-1
								},
							]
						},
						{
						    type: "select",
						    name: "order_clock",
						    title: "酒店打卡",
						    mode: "multiple",
						    options: {},
						    list: [
								{
									value:"入住已打卡",
									key:"start_on_clock",
								},
								{
									value:"入住未打卡",
									key:"start_not_clock",
								},
								{
									value:"离店已打卡",
									key:"end_on_clock",
								},
								{
									value:"离店未打卡",
									key:"end_not_clock",
								},
							]
						},
						{
							type: "input_group",
							name:"time_type,range_date",
							title: "时间",
							input_type:'range_date',
							list: [
								{
									value:"计划开始时间",
									key:"plan_start_time"
								},
								{
									value:"计划结束时间",
									key:"plan_end_time"
								},
								{
									value:"入住时间",
									key:"checkinDate"
								},
								{
									value:"离店时间",
									key:"checkoutDate"
								},
								
							],
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},
						{
							type:"text",
							name:"hotel_name",
							title:"酒店名称"
						},
						{
							type: "input_group",
							name:"address_type,address",
							title: "地点",
							list: [
								{
									value:"计划出发地",
									key:"plan_start_region"
								},
								{
									value:"计划到达地",
									key:"plan_end_region"
								},
								{
									value:"行程出发地",
									key:"start_place"
								},
								{
									value:"行程到达地",
									key:"end_place"
								},
								{
									value:"酒店地址",
									key:"hotel_address"
								},
							]
						},
						{
						    type: "select",
						    name: "order_status",
						    title: "酒店订单状态",
						    mode: "default",
						    options: {},
						    list: [
								{
									value:"已入住",
									key:1
								},
								{
									value:"已退款",
									key:-1
								},
								
							]
						},
						
					],
					...this.$config.form_data_seo
				},
				dataInfo:{},
				selected_fields:[], // 在字段显示设置中被选中了的字段
			};
		},


		async created() {
			
			this.$method.get_department().then(res => {
			    this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
			});
			
			// this.$method.get_role().then(res => {
			//     this.form_data_seo.list.forEach(item => {
			//         if (item.name == "role_id") {
			//             item.list = res;
			//         }
			//     });
			// });
			this.$method.get_role_tree().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "role_id") {
						item.treeData = res;
					}
				});
			});
			
			this.get_ApprovalTypeSelect();
			
			// 将页面初始选中的字段赋予导出功能
			let checkeds = localStorage.getItem('order_export_columns_checkeds');
			this.default_selected_columns = checkeds ? checkeds.split(',') : this.default_selected_columns; // 记住用户字段显示设置最近一次的配置
			this.setExportData(this.default_selected_columns); 
			
			this.$nextTick(()=>{
				// 行程计划状态、行程状态默认设置为“正常”
				this.$method.setSeoFormValue(this,{
					plan_status:0,
					place_status:0,
				})
				
				// 为列表进行初始列/字段的选中及显示
				let list = this.$refs.list;
				list.fixed_seo_data.field = this.default_selected_columns.toString();
				list.screen_columns.checked = this.default_selected_columns;
				list.set_columns();
				list.get_list();
			})
		},
		methods: {
			/**
			 * 由于当前页面中需要传给后端的字段过多，单纯的通过a标签跳转路由传入参数下载EXCEL受到了GET请求的路由字数限制
			 * 因此转为POST请求进行文件下载
			 * 将选中要显示的字段以及筛选表单中的值传给后端获得excel文件地址
			 */
			exportExcel(){
				// 传入后端的field顺序需要和columns一致
				let field_checkeds = [];
				this.columns.map(item=>{
					this.selected_fields.map(vo=>{
						if(item.dataIndex === vo){
							field_checkeds.push(vo);
						}
					})
				})
				tripExport({
					data:{
						field:field_checkeds.toString(),
						...this.$store.state.table_list_seo_data,
					},
					loading:true
				}).then(res=>{
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = this.$config.host + res.data.link;
					link.setAttribute("download", "差旅行程表"); //指定下载后的文件名，防跳转
					document.body.appendChild(link);
					link.click();
				})
			},
			/**
			 * TableList组件中“字段显示设置弹窗”中点击了确定
			 */
			screen_columns_ok(e){
				this.setExportData(e.checkeds);
			},
			/**
			 * 设置导出表格时传入后端的参数
			 * @param {Array} data 需要导出的字段（TableList组件中“字段显示设置"弹窗中勾选了的字段）
			 */
			setExportData(data){
				// 每次进行字段显示设置时将勾选中的字段记录至缓存中
				localStorage.setItem('order_export_columns_checkeds', data);
				this.selected_fields = data;
			},
			// 获取TableList筛选中的审批类型下拉内容
			get_ApprovalTypeSelect() {
				getApprovalTypeSelect().then(res => {
			
					res.data.traffic = res.data.list.map(item => {
						item.key = item.value;
						item.value = item.label;
						return item;
					});
					this.form_data_seo.list.forEach(item => {
						if (item.name == "approval_type") {
							item.list = res.data.list;
						}
					});
				})
			},
			see(key) {
				this.$router.push("/evection/order_details?order_id=" + key.order_id);
			},
			report() {
				this.$router.push({
					path:"/report/modify_report",
					query:{
						report_key:"custom_trip_report",
						...this.$refs.list.seo_data
					}
				});
			},
		}
	};
</script>

<style lang="less">
	@import url("../../../assets/less/app.less");
	.edit_pop{
		.ant-col-19 {
			width: 77%;
		}
		.ant-form-item-label {
			width: 22%;
		}
	}
	.red_color {
		color: red;
	}
	.green_color{
		color: #00CC10;
	}
	
	.recharge_all {
		font-size: 16px;
		display: flex;
		color: #333;
	
		.all_money {
			font-size: 20px;
			color: #FF0000;
			margin-left: 10px;
			margin-right: 30px;
			display: inline-block;
		}
	
	}
</style>
